<template>
  <div class="relative my-6 lg:my-24">
    <Dropdown v-if="!isDesktop"
              :items="items.map(item => ({ key: item.target, label: item.label }))"
              :value="{ key: 'default', label: 'Scroll to' }"
              class="mx-6"
              @change="mobileScrollTrigger"
    />
    <div v-if="isDesktop"
         ref="staticEl"
         class="h-[100px] hidden lg:block"
    />
    <Container v-if="isDesktop"
               class="bottom-0 left-0 right-0 z-50 items-center hidden mx-auto mb-10 duration-500 lg:flex"
               :class="[position === 'hidden' ? 'opacity-0' : '', position === 'static' ? 'absolute' : 'fixed']"
    >
      <div ref="fixedEl"
           class="relative p-2 mx-auto bg-gray-500 bg-opacity-50 rounded-full backdrop-blur-sm"
      >
        <div class="flex items-center space-x-10">
          <a v-for="(item, i) in items"
             :ref="el => menuItems.push({ el, target: item.target })"
             :key="i"
             class="px-4 py-2 text-lg font-normal text-gray-200"
             :href="item.target"
             @click="$lenisScroll(item.target)"
             @mouseover="handleHover"
             @mouseleave="handleHover()"
          >
            {{ item.label }}
          </a>
        </div>
        <div ref="submenuHoverElement"
             class="absolute h-10 -translate-y-1/2 bg-gray-100 rounded-full pointer-events-none top-1/2 bg-opacity-10"
             :style="{
               left: `${submenuHover.offsetLeft}px`,
               width: `${submenuHover.clientWidth}px`,
               opacity: submenuHover.opacity,
             }"
        />
        <div ref="activeElement"
             class="absolute h-10 duration-300 -translate-y-1/2 bg-gray-100 rounded-full pointer-events-none top-1/2 bg-opacity-10"
             :style="{
               left: `${activeState.offsetLeft}px`,
               width: `${activeState.clientWidth}px`,
               opacity: activeState.opacity,
             }"
        />
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { scroll, inView } from 'motion'
import { useRoute, useRouter } from 'vue-router'
import { useScreenSize } from '../../composables/use-screen-size'
import { useNuxtApp } from '#imports'

interface stickyMenuProps {
  items: {
    label: string
    target: string
  }[]
}

const props = defineProps<stickyMenuProps>()
const { isDesktop } = useScreenSize()
const route = useRoute()
const router = useRouter()

const { $lenisScroll } = useNuxtApp()

const fixedEl = ref()
const staticEl = ref()
const menuItems = ref([])

const position = ref('static')

onMounted(() => {
  props.items.forEach(el => {
    inView(el.target, () => {
      const active = menuItems.value.find(item => item.target === el.target)
      if (!active) return
      activeState.value = {
        offsetLeft: active.el.offsetLeft,
        clientWidth: active.el.clientWidth,
        opacity: 1
      }
      return () => ''
    })
  })

  scroll(({ y }) => {
    if (!fixedEl.value) return

    const footer = document.querySelector('footer')

    /// check position of fixed element if its higher than static or lover than footer hide it
    if (fixedEl.value.getBoundingClientRect().top >= footer.getBoundingClientRect().top) {
      position.value = 'hidden'
      activeState.value.opacity = 0
    }
    else if (
      y.current + window.innerHeight - staticEl.value.getBoundingClientRect().height
      <= staticEl.value.getBoundingClientRect().top + y.current
    ) {
      position.value = 'static'
      activeState.value.opacity = 0
    }
    else position.value = 'fixed'
  })
})

const mobileScrollTrigger = (el: { key: string }) => {
  router.push({
    hash: el.key,
    query: route.query
  })
  $lenisScroll(el.key)
}

type SubmenuHoverType = {
  offsetLeft: number
  clientWidth: number
  opacity: number
}

const timeoutEvent = ref(null)

const activeElement = ref()
const activeState = ref<SubmenuHoverType>({
  offsetLeft: 0,
  clientWidth: 0,
  opacity: 0
})

const submenuHoverElement = ref()
const submenuHover = ref<SubmenuHoverType>({
  offsetLeft: 0,
  clientWidth: 0,
  opacity: 0
})

/**
 * Handle submenu hover
 *
 * On submenu hover we set the submenuHover object values to the values of the
 * hovered item. This is used to animate the submenuHoverElement
 *
 * @param event
 */
function handleHover(event?: MouseEvent) {
  // If event, we are hovering over an item
  if (event) {
    // Clear timeout event, since we no longer need to run it, while we are hovering
    clearTimeout(timeoutEvent.value)
    const item = event.target as HTMLElement

    // If the opacity was 0, we set the hover position
    // After a slight delay (10ms), we add the animation duration class
    // to avoid the animation to start from the x = 0 position
    if (submenuHover.value.opacity === 0) {
      submenuHover.value = {
        offsetLeft: item.offsetLeft,
        clientWidth: item.clientWidth,
        opacity: 0
      }

      setTimeout(() => {
        submenuHoverElement.value.classList.add('duration-300')
        submenuHover.value = {
          ...submenuHover.value,
          opacity: 1
        }
      }, 10)
    }
    else {
      // In case the opacity is not 0, we just update the position
      submenuHover.value = {
        offsetLeft: item.offsetLeft,
        clientWidth: item.clientWidth,
        opacity: 1
      }
    }
  }
  else {
    // If no event, we are not hovering over an item, set timeout to clear the hover
    // and animation duration
    timeoutEvent.value = setTimeout(() => {
      submenuHoverElement.value.classList.remove('duration-300')
      submenuHover.value = {
        ...submenuHover.value,
        opacity: 0
      }
    }, 200)
  }
}
</script>
